import { useTranslation } from '@unifyapps/i18n/client';
import Stack from '@unifyapps/ui/_components/Stack';
import { Button } from '@unifyapps/ui/components/Button';

interface DatePickerActionsProps {
  disableApply: boolean;
  disableReset: boolean;
  onApply: () => void;
  onClear: () => void;
  onReset: () => void;
}

export default function DatePickerActions(props: DatePickerActionsProps) {
  const { disableApply, disableReset, onApply, onClear, onReset } = props;
  const { t } = useTranslation('common');
  return (
    <Stack className="p-xl border-secondary gap-x-3 border-t" direction="row">
      <Button color="neutral" disabled={disableReset} onClick={onReset} size="md" variant="soft">
        {t('common:Actions.Reset')}
      </Button>
      <Button className="ms-auto" color="neutral" onClick={onClear} size="md" variant="outline">
        {t('common:Actions.Clear')}
      </Button>
      <Button color="brand" disabled={disableApply} onClick={onApply} size="md" variant="solid">
        {t('common:Actions.Apply')}
      </Button>
    </Stack>
  );
}
