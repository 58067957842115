import type { ReactElement } from 'react';
import { cloneElement, useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Box } from '@unifyapps/ui/components/Box';

const computeItemKey = (index: number, item: ReactElement) => item.key || index;

const itemContent = (_: number, item: React.ReactNode) => (
  <Box className="py-xxs">{cloneElement(item as ReactElement, { className: '!my-0' })}</Box>
);

interface ListBoxVirtuosoListProps {
  options: ReactElement[];
  maxListHeight: number;
  atBottomStateChange: (atBottom: boolean) => void;
  Header?: React.ComponentType<{ context?: Record<string, unknown> }>;
}

function ListBoxVirtuosoList(props: ListBoxVirtuosoListProps) {
  const { options, maxListHeight, atBottomStateChange, Header } = props;

  const [listHeight, setListHeight] = useState(maxListHeight);

  const totalListHeightChanged = useCallback(
    (height: number) => {
      setListHeight(Math.min(height, maxListHeight));
    },
    [maxListHeight],
  );

  const style = useMemo(() => ({ height: listHeight }), [listHeight]);
  const components = useMemo(() => ({ Header }), [Header]);

  return (
    <Virtuoso
      atBottomStateChange={atBottomStateChange}
      atBottomThreshold={200}
      components={components}
      computeItemKey={computeItemKey}
      data={options}
      defaultItemHeight={36}
      itemContent={itemContent}
      style={style}
      totalListHeightChanged={totalListHeightChanged}
    />
  );
}

export default ListBoxVirtuosoList;
