import { Box } from '@unifyapps/ui/components/Box';
import { Typography } from '@unifyapps/ui/components/Typography';
import _map from 'lodash/map';
import { IconButton } from '@unifyapps/ui/components/IconButton';
import ChevronUp from '@unifyapps/icons/outline/ChevronUp';
import ChevronDown from '@unifyapps/icons/outline/ChevronDown';
import useToggle from '@unifyapps/hooks/useToggle';
import { infoboxExpandButtonClassname, infoboxRootVariants } from './InfoBox/styles';

function ErrorMessage({ title, description }: { title: string; description: string }) {
  return (
    <Typography color="text-secondary" variant="text-sm" weight="regular">
      <Typography
        as="span"
        className="text-fg-error-secondary underline"
        variant="text-sm"
        weight="medium"
      >
        {title}
      </Typography>
      : {description}
    </Typography>
  );
}

export function ErrorPanel({ errors }: { errors?: { title: string; description: string }[] }) {
  const hasMultipleErrors = (errors?.length || 0) > 1;

  const [collapsed, { toggle }] = useToggle(false);

  const containerClass = infoboxRootVariants({
    variant: 'error',
    className: 'mt-xl mx-lg p-lg gap-y-md',
  });

  if (!errors || errors.length === 0) {
    return null;
  }

  if (!hasMultipleErrors) {
    return (
      <Box className={containerClass}>
        <ErrorMessage description={errors[0].description} title={errors[0].title} />
      </Box>
    );
  }

  return (
    <Box className={containerClass}>
      <Box className="flex flex-row items-center justify-between">
        <Typography color="text-primary" variant="text-md" weight="semi-bold">
          {`${errors.length} Errors`}
        </Typography>
        <IconButton
          Icon={collapsed ? ChevronDown : ChevronUp}
          className={infoboxExpandButtonClassname}
          color="brand"
          onClick={toggle}
          size="sm"
          variant="soft"
        />
      </Box>
      {collapsed ? null : (
        <Box className="gap-y-xs flex flex-col">
          {_map(errors, (error, index) => (
            <ErrorMessage
              description={error.description}
              key={index.toString()}
              title={error.title}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
