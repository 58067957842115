import { format } from 'date-fns/format';
import { isValid } from 'date-fns/isValid';
import type { DatePickerProps } from '@unifyapps/ui/_components/DatePicker';
import type { DateRange, TimeRange } from './types';

export const INITIAL_TIME_SINCE = '00:00';
export const INITIAL_TIME_UNTIL = '23:59';
const TIME_FORMAT = 'HH:mm';

const SPACE_KEY = ' ';

export function getInitialTime(date?: Date) {
  const formattedDate = date && isValid(date) ? format(date, TIME_FORMAT) : INITIAL_TIME_SINCE;

  return formattedDate;
}

export function getInitialTimeRange(dateRange?: DateRange | null) {
  const since =
    dateRange?.since && isValid(dateRange.since)
      ? format(dateRange.since, TIME_FORMAT)
      : INITIAL_TIME_SINCE;

  const until =
    dateRange?.until && isValid(dateRange.until)
      ? format(dateRange.until, TIME_FORMAT)
      : INITIAL_TIME_UNTIL;

  return {
    since,
    until,
  };
}

export const INITIAL_RANGE: DateRange = { since: undefined, until: undefined };
export const INITIAL_TIME: TimeRange = { since: INITIAL_TIME_SINCE, until: INITIAL_TIME_UNTIL };

export const dateRangeSelectorClassNames: DatePickerProps['classNames'] = {
  head_cell: 'rdp-head_cell !text-sm !font-medium !w-10 !normal-case',
  cell: 'rdp-cell !text-sm',
  table: 'rdp-table !mt-5',
  caption_dropdowns: 'rdp-caption_dropdowns gap-x-2',
  caption_label: 'rdp-caption_label !text-sm !font-medium',
  day_selected: '!bg-active text-secondary',
  day_range_start: 'rdp-day_range_start !bg-brand-solid !text-white',
  day_range_end: 'rdp-day_range_end !bg-brand-solid !text-white',
};

export const dateSelectorClassNames: DatePickerProps['classNames'] = {
  ...dateRangeSelectorClassNames,
  day_selected: '!bg-brand-solid !text-white',
};

export function preventNativePicker(e: React.KeyboardEvent<HTMLInputElement>) {
  // SPACE_KEY is used to open the native date picker in all browsers
  if (e.key === SPACE_KEY) {
    // prevent the default behavior of the space key
    // NOTE: does not work in Firefox
    e.preventDefault();
  }
}
