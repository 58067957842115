import SvgPlus from '@unifyapps/icons/outline/Plus';
import { Box } from '@unifyapps/ui/components/Box';
import type { ButtonVariants, ButtonColors, ButtonSizes } from '@unifyapps/ui/components/Button';
import { Button } from '@unifyapps/ui/components/Button';
import type { IconComponentType } from '@unifyapps/ui/types';
import { clsx } from 'clsx';
import _map from 'lodash/map';
import { useMemo } from 'react';

export function FooterButton<Value>({
  footerAction,
  getOptionLabel,
}: {
  footerAction: {
    option: Value;
    onSelect: () => void;
    variant?: ButtonVariants;
    color?: ButtonColors;
    size?: ButtonSizes;
    className?: string;
    startDecoratorComponent?: IconComponentType;
  };
  getOptionLabel: (option: Value) => string;
}) {
  const {
    option: extraOption,
    onSelect: onSelectExtraOption,
    variant = 'ghost',
    color = 'brand',
    size = 'xs',
    className,
    startDecoratorComponent: _startDecoratorComponent,
  } = footerAction;

  const startDecoratorNode = useMemo(() => {
    if (_startDecoratorComponent) {
      return _startDecoratorComponent;
    }
    return <SvgPlus height="16" width="16" />;
  }, [_startDecoratorComponent]);

  return (
    <Button
      className={clsx(className, '!gap-xs')}
      color={color}
      onClick={onSelectExtraOption}
      size={size}
      startDecoratorNode={startDecoratorNode as React.ReactNode}
      variant={variant}
    >
      {getOptionLabel(extraOption)}
    </Button>
  );
}

export function ListBoxFooter<Value>({
  footerAction,
  getOptionLabel,
  className,
}: {
  footerAction: {
    option: Value;
    onSelect: () => void;
  };
  getOptionLabel: (option: Value) => string;
  className?: string;
}) {
  return (
    <Box
      className={clsx(
        'bg-primary p-sm border-secondary sticky bottom-0 flex w-full flex-row justify-start border-t',
        className,
      )}
    >
      <FooterButton<Value> footerAction={footerAction} getOptionLabel={getOptionLabel} />
    </Box>
  );
}

export function MultiActionListBoxFooter<Value>({
  footerActions,
  getOptionLabel,
  className,
}: {
  footerActions: {
    option: Value;
    onSelect: () => void;
  }[];
  getOptionLabel: (option: Value) => string;
  className?: string;
}) {
  return (
    <Box
      className={clsx(
        'bg-primary p-sm border-secondary sticky bottom-0 flex w-full flex-row justify-start',
        className,
      )}
    >
      {_map(footerActions, (footerAction, index) => (
        <FooterButton<Value>
          footerAction={footerAction}
          getOptionLabel={getOptionLabel}
          key={index}
        />
      ))}
    </Box>
  );
}
