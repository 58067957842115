import type { LookupRequest } from '@unifyapps/network/generated/models/lookupRequest';
import { getLookupQueryKey } from '@unifyapps/network/generated/lookup-rest-api/lookup-rest-api';
import type { QueryKey } from '@unifyapps/network/react-query';
import { keepPreviousData, useInfiniteQuery } from '@unifyapps/network/react-query';
import { executeFetch } from '@unifyapps/network/utils/fetch';

export const getInitialPageParam = (pageSize = 30) => ({
  page: { limit: pageSize, offset: 0 },
});

export type LookupOptionType = {
  id: string;
  name: string;
};

export type LookupByQueryResponse<T> = {
  objects: T[];
  hasMore: boolean;
};

export const useLookupInfiniteQuery = <T>(
  lookupQuery: LookupRequest,
  pageSize = 30,
  queryOptions?: { queryKey?: QueryKey },
) => {
  return useInfiniteQuery({
    queryKey: queryOptions?.queryKey ?? getLookupQueryKey(lookupQuery),
    queryFn: async ({ pageParam }) => {
      return executeFetch<{ response: LookupByQueryResponse<T> }>({
        url: `/api/lookup`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { ...lookupQuery, ...pageParam },
      });
    },
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.response.hasMore) {
        return {
          page: {
            limit: pageSize,
            offset: lastPageParam.page.offset + lastPageParam.page.limit,
          },
        };
      }
      return null;
    },
    initialPageParam: getInitialPageParam(pageSize),
    placeholderData: keepPreviousData,
  });
};
