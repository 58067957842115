import React, { forwardRef, useCallback, useMemo } from 'react';
import type { ReactElement, ReactNode, ElementType, RefObject } from 'react';
import type { ListProps } from '@unifyapps/ui/_components/List';
import List from '@unifyapps/ui/_components/List';
import { Popover } from '@unifyapps/ui/components/Popover';
import { Box } from '@unifyapps/ui/components/Box';
import { useTranslation } from '@unifyapps/i18n/client';
import { useSlot } from '@unifyapps/ui/slots';
import type { SlotProps } from '@unifyapps/ui/slots/types';
import { ErrorPanel } from '../ErrorPanel';
import ListBoxVirtuosoList from './ListBoxVirtuosoList';

type ListBoxProps = Omit<ListProps, 'slotProps'> & {
  open: boolean;
  anchorEl: HTMLElement | null;
  footerDecoratorNode?: ReactNode;
  error?: Error | null;
  fetchNextPage?: () => void;
  disableVirtualization?: boolean;
  ownerState?: object;
  slots?: {
    listboxfooter?: ElementType;
    listboxheader?: React.ComponentType;
  };
  slotProps?: {
    listboxfooter?: SlotProps<'div', object, object>;
    listboxheader?: SlotProps<'div', object, object>;
  };
};

const ListBox = forwardRef(
  (props: ListBoxProps, ref: RefObject<HTMLUListElement>): ReactElement | null => {
    const { t } = useTranslation();
    const {
      open,
      slots,
      slotProps,
      anchorEl,
      error,
      fetchNextPage,
      disableVirtualization,
      ownerState,
      ...rest
    } = props;

    const options = Array.isArray(props.children) ? (props.children[0] as ReactElement[]) : [];

    const [ListBoxFooter, listBoxFooterProps] = useSlot('listboxfooter', {
      elementType: (() => null) as ElementType,
      externalForwardedProps: { slots, slotProps },
      className: props.children ? undefined : 'border-b-0 pb-0',
      ownerState: {},
    });

    const [ListBoxHeader, listBoxHeaderProps] = useSlot('listboxheader', {
      elementType: (() => null) as ElementType,
      externalForwardedProps: { slots, slotProps },
      className: '',
      ownerState: {},
      additionalProps: { autoCompleteProps: ownerState, filteredElements: options },
    });

    const atBottomStateChange = useCallback(
      (atBottom: boolean) => {
        atBottom && fetchNextPage?.();
      },
      [fetchNextPage],
    );

    const VirtuosoListHeader = useMemo(() => {
      if (!slots?.listboxheader || disableVirtualization) {
        return undefined;
      }

      return function Header() {
        return <ListBoxHeader {...listBoxHeaderProps} />;
      };
    }, [ListBoxHeader, disableVirtualization, listBoxHeaderProps, slots?.listboxheader]);

    if (!open) {
      return null;
    }

    let contentEl: ReactElement;

    if (error) {
      contentEl = (
        <List onMouseDown={props.onMouseDown} ref={ref} style={props.style}>
          <Box className="mb-xl">
            <ErrorPanel
              errors={[
                {
                  title: t('common:ErrorFetchingResults'),
                  description:
                    (error.message as string | undefined) ?? t('common:DropdownTryAgain'),
                },
              ]}
            />
          </Box>
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    } else if (disableVirtualization) {
      contentEl = (
        <List {...rest} className="py-xs max-h-[400px] overflow-auto rounded-md" ref={ref}>
          <ListBoxHeader {...listBoxHeaderProps} />
          {props.children}
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    } else {
      contentEl = (
        // @ts-expect-error - using div as children are not li
        <List {...rest} className="py-xs rounded-md" component="div" ref={ref}>
          {options.length ? (
            <Box className="max-h-[400px] overflow-auto">
              <ListBoxVirtuosoList
                Header={VirtuosoListHeader}
                atBottomStateChange={atBottomStateChange}
                maxListHeight={400}
                options={options}
              />
            </Box>
          ) : (
            props.children
          )}
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    }

    return (
      <Popover anchorEl={anchorEl} open>
        <Popover.Content ignoreBoundary popupClassName="z-popup" useAnchorElWidth>
          {contentEl}
        </Popover.Content>
      </Popover>
    );
  },
);

ListBox.displayName = 'ListBox';

type ListBoxRendererProps = Pick<
  ListBoxProps,
  'error' | 'slots' | 'slotProps' | 'fetchNextPage' | 'disableVirtualization'
>;

export const listBoxRenderer = ({
  slots,
  slotProps,
  fetchNextPage,
  error,
  disableVirtualization,
}: ListBoxRendererProps) => {
  const ListBoxComponentWrapper = forwardRef(
    (
      listBoxProps: ListProps & { open: boolean; anchorEl: HTMLElement | null },
      ref: RefObject<HTMLUListElement>,
    ) => {
      return (
        <ListBox
          {...listBoxProps}
          disableVirtualization={disableVirtualization}
          error={error}
          fetchNextPage={fetchNextPage}
          ref={ref}
          slotProps={slotProps}
          slots={slots}
        />
      );
    },
  );

  ListBoxComponentWrapper.displayName = 'ListBoxComponentWrapper';

  return ListBoxComponentWrapper;
};

export type { ListProps };

export default ListBox;
